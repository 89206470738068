
<nb-layout *ngIf="data.paramInit">
    <nb-layout-column>
      <div class="contact-wrapper">
        <div>
            <div class="row justify-content-center">
              <div class="col-lg-7 col-xl-6">
                <div class="brand text-center pt--20">
                  <a href="">
                    <img class="tabledealer-logo" src="../../assets/images/tabledealer-header.svg" alt="">
                  </a>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-7 col-xl-6">
                <div class="main-block text-center">
                  <div class="omega-form">
                    <form action="./">
                      <div class="form-title mb--35">
                        <h2 class="title">Anmeldung</h2>
                        <p>Geben Sie Ihre Kontodaten unten ein</p>
                      </div>
                      <div class="form-group">
                        <input name="email"
                        id="input-email"
                        pattern=".+@.+\..+" 
                        [(ngModel)]="email"
                        autofocus
                        class="form-control" type="email"   placeholder="Email">
                      </div>
                      <div class="form-group forget-block">
                        <input
                        [(ngModel)]="password"
                        id="input-password"
                         class="form-control" 
                         name="password"
                         type="password" placeholder="Passwort"> 
                      </div>
  
                      <div class="form-group button-block">
                        <button class="form-btn" (click)="authenticate()">Anmelden</button>
                        <p class="sign-up-text">Sie haben noch kein Konto? <a href="https://tabledealer.at/contact-2.html">Call us</a></p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
    </div>
  </div>
</nb-layout-column>
</nb-layout>

<nb-layout *ngIf="!data.paramInit">
        <nb-layout-column>
            <div class="contact-wrapper">
            <div>
                <div class="row justify-content-center">
                    <div class="col-lg-7 col-xl-6">
                    <div class="brand text-center pt--20">
                        <a href="">
                        <img class="tabledealer-logo" src="../../assets/images/tabledealer-header.svg" alt="">
                        </a>
                    </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-7 col-xl-6">
                    <div class="main-block text-center mt-4">
                        <p>Kein Veranstalter angegeben. Das ist eine falsche URL.</p>
                    </div>
                    </div>
                </div>
        </div>
        </div>
    </nb-layout-column>
</nb-layout>
 