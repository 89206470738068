<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <img (click)="navigateHome()" src="../../../../assets/images/tabledealer-header.svg" height="40vh"  class="ml-2">
     
  </div>
  <!--<nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
  <button nbButton (click)="toggleTheme()"ghost><nb-icon [icon]="iconstatus"></nb-icon></button>
  <nb-actions size="small">
        <nb-action class="control-item" icon="shopping-bag-outline" [badgeText]="data.bestellArtikelSum" badgeStatus="danger" badgePosition="bottom right" badge-text-font-weight="1000"  ></nb-action>
  </nb-actions>
</div>
<!--
<div class="header-container">
  <nb-actions size="small">

    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
-->